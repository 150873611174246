<template>
  <Modal
    class="drivers__variables"
    :show="isNewTitlePageToolModalVisible"
    @close="$emit('close-title-page-tool')"
    :widerFormat="true"
  >
    <template #header>
      <VisibleText> Add title page </VisibleText>
    </template>
    <form
      class="user-guide__form"
      id="create-header"
      style="display: flex; flex-direction: column"
    >
      <div>
        <h2><VisibleText>Step 1: Choose a slide</VisibleText></h2>

        <p>
          <VisibleText
            >Title pages add a visual banner to your slide. Please select a
            slide or create one.</VisibleText
          >
        </p>

        <div v-if="!slides.length" style="padding: 0 2em">
          <p style="color: var(--warning-text-colour); font-weight: 800">
            <VisibleText
              >No existing slides were detected, create a new one:</VisibleText
            >
          </p>
          <div>
            <div class="project-analysis-item__input">
              <label
                class="value project-analysis-item__input-label"
                :for="uuid"
              >
                <span> Please name this slide </span>
              </label>
              <input
                style="width: 15em"
                name="slide-name"
                type="text"
                :id="uuid"
                v-model="newSlideName"
              />
            </div>
          </div>
        </div>
        <div v-else style="padding: 0 2em">
          <p style="color: var(--success-text-colour); font-weight: 800">
            <VisibleText>Existing slides have been found.</VisibleText>
          </p>

          <div style="display: flex; flex-direction: row">
            <button
              class="project-analysis__header-add-btn"
              style="margin-left: 0; border-radius: 30px 0 0 30px"
              :style="
                !isAddingNewSlide
                  ? 'background-color: var(--highlight--gray); color: var(--teritary-ui-colour)'
                  : ''
              "
              @click.prevent="isAddingNewSlide = true"
            >
              <VisibleText>Create new slide</VisibleText>
            </button>
            <button
              class="project-analysis__header-add-btn"
              style="margin-left: 0; border-radius: 0 30px 30px 0"
              :style="
                isAddingNewSlide
                  ? 'background-color: var(--highlight--gray); color: var(--teritary-ui-colour)'
                  : ''
              "
              @click.prevent="isAddingNewSlide = false"
            >
              <VisibleText>Choose from existing slide</VisibleText>
            </button>
          </div>

          <div v-if="!isAddingNewSlide" style="margin: 1em">
            <label style="font-size: 0.8em">
              <VisibleText>List of slides:</VisibleText>
            </label>
            <div class="select-wrapper">
              <select
                class="select"
                id="saved-slide-filter"
                v-model="selectedSlide"
              >
                <option
                  v-for="(slide, index) in slides"
                  :value="slide"
                  :key="index"
                >
                  {{ slide.name }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="isAddingNewSlide" style="margin: 1em">
            <div style="width: 15em">
              <div class="project-analysis-item__input">
                <label
                  class="value project-analysis-item__input-label"
                  :for="uuid"
                >
                  <span> Please name this slide </span>
                </label>
                <input
                  style="width: 100%"
                  name="slide-name"
                  type="text"
                  :id="uuid"
                  v-model="newSlideName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <label for="content"
        ><h2>
          <VisibleText>Step 2: Enter text for title</VisibleText>:
        </h2></label
      >
      <input
        class="contactUs__input"
        type="text"
        id="content"
        name="content"
        v-model="newTitlePageContent"
      />

      <label for="subContent"
        ><h2>
          <VisibleText>Step 3: Enter text for sub-title (optional)</VisibleText
          >:
        </h2></label
      >
      <input
        class="contactUs__input"
        type="text"
        id="subContent"
        name="subContent"
        v-model="newTitlePageSubContent"
      />

      <label
        ><h2>
          <VisibleText>Step 4: Add date to title page (optional)</VisibleText>:
        </h2></label
      >
      <label class="form-label form-checkbox-label">
        <input
          class="table__toolbar-checkbox-select-input"
          type="checkbox"
          id="titlePageDate"
          name="titlePageDate"
          v-model="newTitlePageDate"
        />
        <VisibleText>Yes</VisibleText>
      </label>

      <div class="contactUs--content-right">
        <button
          class="landing-page__button"
          :class="disableButton"
          @click.prevent="sendForm"
          :disabled="disableButton !== ''"
        >
          <VisibleText>Add to report</VisibleText>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"

export default {
  name: "NewTitlePageToolModal",
  components: {
    Modal
  },
  props: {
    isNewTitlePageToolModalVisible: {
      default: () => false,
      type: Boolean
    },
    slides: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      newTitlePageContent: "",
      newTitlePageSubContent: "",
      newTitlePageDate: null,
      newSlideName: "New slide",
      uuid: this.$pigeonline.createUUID(),
      selectedSlide: "",
      isAddingNewSlide: false,
      resetSlideChecker: false
    }
  },
  mounted() {
    if (!this.slides.length) {
      this.isAddingNewSlide = true
    }
  },
  computed: {
    // visiblePlaceholder() {
    //   return this.checkTranslationCache(
    //     "Please name this slide",
    //     "slide-name",
    //     this.$store.getters.getLanguage
    //   )
    // },
    disableButton() {
      if (
        this.newTitlePageContent === "" ||
        (this.isAddingNewSlide && !this.newSlideName.length) ||
        (!this.isAddingNewSlide && !this.selectedSlide)
      ) {
        return "landing-page__button-disabled"
      }
      return ""
    }
  },
  methods: {
    sendForm() {
      if (this.newTitlePageContent) {
        if (this.isAddingNewSlide) {
          this.$emit(
            "add-new-title-page",
            this.newTitlePageContent,
            this.newTitlePageSubContent,
            this.newTitlePageDate,
            null,
            this.newSlideName
          )
        } else {
          this.$emit(
            "add-new-title-page",
            this.newTitlePageContent,
            this.newTitlePageSubContent,
            this.newTitlePageDate,
            this.selectedSlide,
            this.newSlideName
          )
        }
        this.isAddingNewSlide = false
      }
      this.$emit("close-title-page-tool")
    }
  },
  watch: {
    isNewTitlePageToolModalVisible: function (val) {
      if (val)
      {
        setTimeout(() => {
          if (this.slides.length) {
            this.isAddingNewSlide = false
            this.selectedSlide = this.slides[0]
          }
        }, 100)
      }
    }
  }
}
</script>
